<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Randevu Planla</h2>
        <div style="position: absolute; right: 10px">
          <a class="btn btn-primary" title="Tarih Ekle" @click="openDialog"
            ><i style="color: white" class="fa fa-plus"></i
          ></a>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            class="col-xl-3 col-lg-3 col-md-2 m-1 item-dc"
            :style="item.appointment_not==0?'color:#ddd':''"
            v-for="(item, i) in selectedDate"
            :key="i"
          >
            <b>Randevu Tarihi:</b><br />
            <small>{{ item.appointment_date }}</small
            ><br />
            <small v-if="item.appointment_not==0">Randevu tarihi geçmiş</small>
            <hr class="m-1" v-if="item.appointment_not==1" />
            <div class="row col-12" v-if="item.appointment_not==1">
              <a
                class="btn btn-sm btn-primary"
                title="Tarihi Sil"
                @click="removeItem(i)"
                ><i class="fa fa-remove"></i
              ></a>
            </div>
          </div>
          <hr class="m-1"/>
          <div class="col-12">
            <input type="button" class="btn btn-sm btn-primary" value="Kaydet" @click="save" />
          </div>
        </div>
      </div>
      <div class="modal" tabindex="-1" role="dialog" id="appointmentDialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <template v-if="$store.state.user_id > 0">
                <DateCalendar
                  :clickSize="clickSize"
                  :userid="$store.state.user_id"
                  @response="handleSelectedDate($event)"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <style>
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}
.item-dc {
  box-shadow: 0px 4px 9px -2px #eee;
  border-radius: 5px;
  border: 1px solid #ececec;
  padding: 10px;
}
.border-dotted {
  border-bottom: 1px dashed #ccc;
}
</style>
  <script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import DateCalendar from "./Components/DateCalendar.vue";
export default {
  name: "PreAppointmentDetailPlan",
  components: { DateCalendar },
  created() {
    document.title = "Randevu Planla";
    let info = {
      id:this.$route.query.id
    }
    this.$store
          .dispatch("preAppointmentPlanGet", info)
          .then((value) => {
            this.selectedDate=value.list;
          })
    if (this.$route.query.id && this.$route.query.id != null) {
      this.id = this.$route.query.id;
    }
  },
  data() {
    return {
      id: 0,
      selectedDate: [],
      clickSize:0,
    };
  },
  methods: {
    save(){
      let appointmentControl = {
        id: this.id,
        list:JSON.stringify(this.selectedDate),
        };
      this.$store
          .dispatch("preAppointmentPlanSet", appointmentControl)
          .then((value) => {
            this.$vToastify.warning("Kayıt Başarılı", "Uyarı!");
          })
    },
    removeItem(index) {
      this.selectedDate.splice(index, 1);
    },
    openDialog() {
      //Tarih Seçim ekranını açar
      this.clickSize++;
      $("#appointmentDialog").modal("show");
      
    },
    handleSelectedDate: function (e) {
      $("#appointmentDialog").modal("hide");
      this.dateControl(this.$store.state.user_id, e);
    },
    dateControl(id, date) {
      //Listede Ekli olan tarih eklenmeye çalışıyorsa?
      let check1 = true;
      this.selectedDate.forEach((e) => {
        if (e.appointment_hour_id == date[1]) {
          check1 = false;
        }
      });
      if (check1) {
        let appointmentControl = {
          id: id,
          date: date[0],
        };
        this.$store
          .dispatch("appointmentControl", appointmentControl)
          .then((value) => {
            if (value.type == "success") {
              //Tarihte İşlem Yapılabilir
              let item = {
                appointment_not:1,
                appointment_date: value.user.appointment_date,
                appointment_hour_id: value.user.appointment_hour_id,
              };
              this.selectedDate.push(item);
            } else {
              if (value.message == "ERRxUDF") {
                this.$vToastify.warning("Hatalı tarih formatı", "Uyarı!");
                this.$router.go(-1);
              }
              if (value.message == "ERRxDEX") {
                this.$vToastify.warning(
                  "Randevu süresi geçmişten bir gün olamaz",
                  "Uyarı!"
                );
              }
              if (value.message == "ERRxDNF") {
                this.$vToastify.warning("Randevu tarihi bulunamadı", "Uyarı!");
              }
            }
          });
      } else {
        Swal.fire("Ekli olan tarih tekrar eklenemez!", "", "error");
      }
    },
  },
  watch: {
    "$route.query.id": function () {},
  },
};
</script>